import React from 'react';
import { graphql } from 'gatsby';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import PropTypes from 'prop-types';

import { Button, Container } from '@material-ui/core';

import Layout from '../../components/Layout';
import SEO from '../../components/SEO';
import Ribbon from '../../components/Ribbon';
import CardWide from '../../components/Cards/CardWide';
import Maze from '../../components/Maze';
import { duurzaamData } from '../../lib/overviewData';

export const query = graphql`
  query {
    mobileImage: file(relativePath: { eq: "duurzaam/duurzaam-11.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    desktopImage: file(relativePath: { eq: "duurzaam/duurzaam-11.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1500, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    CardHorizontal1Image: file(
      relativePath: { eq: "bathroom/bathroom-2.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    gridImages1: file(relativePath: { eq: "duurzaam/duurzaam.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    gridImages2: file(relativePath: { eq: "duurzaam/duurzaam-1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    gridImages3: file(relativePath: { eq: "duurzaam/duurzaam-3.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    gridImages4: file(relativePath: { eq: "duurzaam/duurzaam-4.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;

const DuurzaamOverview = ({ data, path, location }) => {
  const seo = {
    title: `E S I – Duurzaam overzicht`,
    description: `Alle producten die E S I aanbiedt om uw installatie duurzamer te maken.`,
    pathname: path,
    location,
  };

  const sources = [
    data.mobileImage.childImageSharp.fluid,
    {
      ...data.desktopImage.childImageSharp.fluid,
      media: '(min-width: 970px)',
    },
  ];

  const GridImages = [
    data.gridImages1,
    data.gridImages2,
    data.gridImages3,
    data.gridImages4,
  ];

  const ribbon = {
    image: sources,
    heading: `E S I – Duurzaam overzicht`,
    pitchLine: `Alle producten die E S I aanbiedt om uw installatie duurzamer te maken.`,
  };

  const textTop = {
    header: `Hoe kan je duurzaam worden met E S I - Installatietechniek B.V.?`,
    textWithLink: () => (
      <>
        <p>
          De ontwikkelingen op het gebied van duurzame oplossingen volgen elkaar
          in een rap tempo op. Voor wie flink wil besparen op de energierekening
          of zelfs zelfvoorzienend wil zijn, zijn er talloze oplossingen bij E S
          I - Installatie.
        </p>
      </>
    ),
  };
  const textBottom = {
    header: `Even persoonlijk contact?`,
    textWithLink: () => (
      <>
        <p>
          Aarzel dan zeker niet om te bellen. Dan proberen we samen uw problemen
          op te lossen
        </p>
        <div className='hidden-sm hidden-md hidden-lg'>
          <a href='tel:0642090304'>
            <Button
              role='button'
              variant='contained'
              className='btn btn--secondary heading__button mt-4x '
            >
              <i className='material-icons mr-1x'>phone</i>Krijg advies op maat
            </Button>
          </a>
        </div>
        <AniLink fade to='/contact' className='hidden-xs'>
          <Button
            role='button'
            variant='contained'
            className='btn btn--secondary heading__button mt-4x '
          >
            <i className='material-icons mr-1x'>phone</i>Krijg advies op maat
          </Button>
        </AniLink>
      </>
    ),
  };

  return (
    <Layout>
      <SEO
        title={seo.title}
        description={seo.description}
        location={location}
      />
      <Ribbon
        fluid={ribbon.image}
        heading={ribbon.heading}
        pitchLine={ribbon.pitchLine}
      />
      <Container fixed className='mt-10x mb-10x'>
        <CardWide
          className='center-xs'
          headerElem='h2'
          headerTitle={textTop.header}
          data-sal='slide-left'
        >
          <>
            {textTop.inner &&
              textTop.inner.map((text, i) => <p key={`top-${i}`}>{text}</p>)}
            {textTop.textWithLink && textTop.textWithLink()}
          </>
          <></>
        </CardWide>
        <Maze data={duurzaamData} path={path} gridImages={GridImages} />
        <CardWide
          className='center-xs mt-10x'
          headerElem='h2'
          headerTitle={textBottom.header}
          data-sal='slide-bottom'
        >
          <>
            {textBottom.inner &&
              textBottom.inner.map((text, i) => <p key={i}>{text}</p>)}
            {textBottom.textWithLink && textBottom.textWithLink()}
          </>
          <></>
        </CardWide>
      </Container>
    </Layout>
  );
};

DuurzaamOverview.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
  path: PropTypes.string,
};

export default DuurzaamOverview;
